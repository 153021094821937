<template>
  <div>
    <form-wizard
      ref="transferWizardIndex"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Kaydet"
      back-button-text="Geri"
      class="mb-3">
      <!-- :before-change="validationForm" -->
      <tab-content :title="$t('E-Mail')">
        <b-row>
          <b-col md="12">
            <PrecheckPersonEmail :personData="personData" :isNext="isNext" :$v="$v"/>
          </b-col>
        </b-row>
      </tab-content>
      <!-- accoint details tab -->
      <tab-content :title="$t('Create Facility User')">
  <b-col cols="12">
    <b-card class="media-list">
      <b-row class="d-flex align-items-center">
        <b-col class="profile-side">
          <b-row class="my-0 css-reset">
            <b-media class="mb-2 css-reset">
              <b-img ref="previewEl" :src="userImg" class="profile-img my-2" center />
            </b-media>
          </b-row>
          <b-row class="center profile-edit-buttons">
            <div class="d-flex flex-wrap">
                <b-button
                  variant="primary upload-button"
                  @click="toggleShow">

                <span class="d-none d-sm-inline">
                    <b-icon-capslock-fill class="up-icon" />
                    <span class="up-text">{{ $t("Upload") }}</span>
                </span>
                <b-icon-capslock-fill class="up-icon d-inline d-sm-none" />
                  </b-button>
                <my-upload field="img"
                    @crop-success="cropSuccess"
                    v-model="show"
                    :width="150"
                    :height="150"
                    langType="tr"
                    :params="params"
                    :headers="headers"
                    img-format="png">
                </my-upload>
              <b-button
                variant="danger remove-button"
                class="ml-1"
                @click="removePhoto"
              >
                <span class="d-none d-sm-inline">
                  <b-icon-trash class="remove-icon" />
                  <span class="remove-text">{{ $t("Remove") }}</span>
                </span>
                <b-icon-trash class="remove-icon d-inline d-sm-none" />
              </b-button>
            </div>
          </b-row>
        </b-col>
        <b-col>
          <b-form ref="form">
            <b-row>
              <b-col cols="12" md="6">
                   <b-form-group :label="$t('Name')" label-for="first-name">
                        <b-input-group>
                          <b-form-input
                            @blur="$v.personData.name.$touch()"
                            :class="{ 'is-invalid': $v.personData.name.$error }"
                            id="first-name"
                            type="text"
                            :placeholder="$t('Name')"
                            v-model="personData.name"/>
                          <small v-if="isFinal && !$v.personData.name.required"
                            class="form-text text-danger" >{{ $t("The name field is required") }}</small>
                        </b-input-group>
                      </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group :label="$t('Surname')" label-for="last-name">
                        <b-input-group>
                          <b-form-input
                            @blur="$v.personData.last_name.$touch()"
                            :class="{'is-invalid': $v.personData.last_name.$error,}"
                            id="last-name"
                            type="text"
                            :placeholder="$t('Surname')"
                            v-model="personData.last_name"/>
                          <small
                            v-if="isFinal && !$v.personData.last_name.required" class="form-text text-danger">{{ $t("The surname field is required") }}</small>
                        </b-input-group>
                      </b-form-group>
              </b-col>
            </b-row>
            <b-row>
          <b-col  cols="12" md="6">
            <b-form-group :label="$t('E-Mail')" label-for="email">
              <b-form-input
                @blur="$v.personData.email.$touch()"
                id="email"
                v-model="personData.email"
                type="email"
                disabled
              />
              <small
                v-if="isFinal && !$v.personData.email.required"
                class="form-text text-danger"
                >{{ $t("This field is required") }}</small
              >
              <small
                v-if="isFinal && !$v.personData.email.email"
                class="form-text text-danger"
                >{{ $t("The email field must be a valid email") }}</small
              >
            </b-form-group>
          </b-col>
              <b-col cols="12" md="6">
                      <b-form-group :label="$t('Phone')" label-for="phone">
                        <b-input-group>
                          <b-form-input
                              @change="$v.personData.phone_number.$touch()"
                              :class="{ 'is-invalid': $v.personData.phone_number.$error }"
                              id="phone_number"
                              type="number"
                              v-model="personData.phone_number"
                              :placeholder="$t('Phone')"/>
                        </b-input-group>
                            <small v-if="isFinal && !$v.personData.phone_number.required" class="form-text text-danger" > {{$t("The phone number field format is invalid")}}</small>
                            <small v-if="isFinal && !$v.personData.phone_number.numeric" class="form-text text-danger" > {{$t("The phone number field format is invalid")}}</small>
                            <small v-if="isFinal && !$v.personData.phone_number.maxLength" class="form-text text-danger" >{{ $t("The phone number field format is invalid")}}</small>
                      </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group :label="$t('Birthday')" label-for="birthday" class="mb-1">
                        <b-input-group class="mb-0">
                          <flat-pickr
                            :placeholder="placeholder"
                            v-model="personData.birthday"
                            :class="{'is-invalid': $v.personData.last_name.$error,}"
                             class="form-control"
                            :config="config"/>

                      </b-input-group>
                          <small
                            v-if="isFinal && !$v.personData.birthday.required"
                            class="form-text text-danger">{{ $t("The birthday field is required") }}</small>

                    </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                 <div>
                  <label for="example-input">{{ $t("Gender") }}</label>
                      </div>
                      <div>
                        <b-button-group>
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            size="md"
                            :variant="maleColor"
                            @click="getGender('male')">
                            {{ $t("Male") }}
                          </b-button>
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            :variant="femaleColor"
                            size="md"
                            @click="getGender('female')">
                            {{ $t("Female") }}
                          </b-button>
                        </b-button-group>
                      </div>
                       <small v-if="isFinal && !$v.personData.gender.numeric" class="form-text text-danger" > {{$t("Gender required")}}</small>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-card>
  </b-col>
      </tab-content>
      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-right">
          <b-button
            v-if="props.activeTabIndex === 0"
            @click="facilityCreateNext()"
            size="md"
            variant="primary">
            {{ $t("Next") }}
          </b-button>
        </div>
        <div class="wizard-footer-right">
          <b-button
            v-if="props.activeTabIndex === 0"
            :to="{ name: 'studio_user_list' , params: {studioID: $route.params.studioID} }"
            size="md"
            class="mr-1"
            variant="outline-primary"
          >
            {{ $t("Back") }}
          </b-button>
        </div>
        <div class="wizard-footer-right">
          <b-button
            v-if="props.activeTabIndex === 1"
            class="mr-1 shadow update-button"
            @click="validationForm()"
            size="md"
            variant="outline-success"
          >
           <b-icon-check></b-icon-check>
            {{ $t("Save") }}
          </b-button>
        </div>
        <div class="wizard-footer-right">
          <b-button
            v-if="props.activeTabIndex === 1"
            :to="{ name: 'studio_user_list', params: {studioID: $route.params.studioID} }"
            size="md"
            class="mx-1 shadow cancel-button"
            variant="outline-danger">
            <b-icon-dash-circle></b-icon-dash-circle>
            {{ $t("Cancel") }}
          </b-button>
        </div>
      </template>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import { ref } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { email, maxLength, minLength, numeric, required } from 'vuelidate/lib/validators'
import myUpload from 'vue-image-crop-upload/upload-2.vue'

import flatPickr from 'vue-flatpickr-component'
import { Turkish } from 'flatpickr/dist/l10n/tr.js'

import {
  BAvatar,
  BBreadcrumb,
  BBreadcrumbItem,
  BButton,
  BButtonGroup,
  BCard,
  BCardCode,
  BCol,
  BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BMedia,
  BRow,
  BVToastPlugin,
} from 'bootstrap-vue'
import PrecheckPersonEmail from '@/components/PrecheckPersonEmail/PrecheckPersonEmail'

export default {
  setup() {
    const userImg = ref("");
    const previewEl = ref(null);
    let refInputEl = ref(null);
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, function(
      base64
    ) {
      userImg.value = base64;
    });
    return {
      refInputEl,
      inputImageRenderer,
      userImg,
      previewEl,
    };
  },
  components: {
    PrecheckPersonEmail,
    BAvatar,
    BVToastPlugin,
    FormWizard,
    TabContent,
    BBreadcrumb,
    BBreadcrumbItem,
    BButton,
    BButtonGroup,
    BFormDatepicker,
    BInputGroupAppend,
    BImg,
    BInputGroup,
    BMedia,
    BCard,
    BCardCode,
    Cleave,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    ToastificationContent,
    flatPickr,
    'my-upload': myUpload
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      placeholder: "dd.mm.YYYY",
      // min_date: "1940-01-01",
      // max_date: "2009-12-31",
      //studio_id: "",
      updateInsertType: 0,
      formatted: "",
      selected: "",
      personData: {
        company_branch_id:"",
        name:"",
        last_name:"",
        birthday: "",
        phone_number: "",
        gender: "-",
        email: "",
       },
      maleColor: "outline-primary",
      femaleColor: "outline-primary",
    config: {
        dateFormat: "d.m.Y",
        locale: this.$i18n.locale === 'en' ? '' : Turkish,
        maxDate: "12.12.2009",
        minDate: "01.01.1940",
      },
      block: {
        blocks: [25],
        uppercase: false,
      },
      isNext: false,
      isFinal: false,
      createType: '',

      show: false,
      params: {
        token: '123456798',
        name: 'avatar'
      },
      headers: {
        smail: '*_~'
      },
      userImg: ''
    };
  },
  methods: {
    validationForm() {

        this.isFinal=true;
         if (this.$v.$invalid)
              return;

      // if (this.$refs.transferWizardIndex.activeTabIndex === 0) { //ilk adım
      //   if (
      //     this.$v.personData.email.required && //eğer valid doğrulanırsa
      //     this.$v.personData.email.email
      //   ) {
      //     // this.updateInsertPersonData(); //...diğer adıma geç
      //     this.$database.AccountService.get_user_by_mail({
      //       EMail: this.personData.email,
      //       CompanyBranchId: this.personData.company_branch_id
      //     }).then(res => {
      //       // console.log(res);
      //       if(res.IsSuccess === true || res.IsSuccess === 1){
      //         this.$router.push({
      //           name: 'facility_user_edit',
      //           params: { personID: res.Result.PersonId }
      //         })
      //       }else {
      //         this.$refs.transferWizardIndex.nextTab()
      //       }
      //     })
      //   }
      //   else{ //doğrulanamadıysa
      //     this.isNext = true; //uyarı mesajlarını tetikle
      //   }
      // } else { //final adımı
      //     if(this.$v.personData.name.required && this.$v.personData.last_name.required && this.$v.personData.phone_number.check && this.$v.personData.email.required)
      //     { //eğer valid doğrulanırsa
            //this.createDistributor(); //customer oluştur
            this.$database.DistributorService.FacilityPersonCreate(
            {
                CompanyBranchId: this.personData.company_branch_id,
                FirstName: this.personData.name,
                LastName: this.personData.last_name,
                Email: this.personData.email.toLowerCase(),
                PhoneNumber: this.personData.phone_number,
                Birthday:this.$functions.ConvertToEnglishDateFormat(this.personData.birthday),//this.$functions.ConvertDateWithLocalLanguage(this.personData.birthday),
                Gender: this.personData.gender,
                UserImg: this.userImg, //şu anlıkAppNavbarVerticalLayout
            }

          // this.$models.personCreateModel(this.personData.company_branch_id, this.personData.name, this.personData.last_name, this.personData.email, this.personData.phone_number, this.$functions.ConvertToEnglishDateFormat(this.personData.birthday),this.personData.gender,this.userImg)
            ).then(res => {
              if(res.IsSuccess === true || res.IsSuccess === 1){
                this.$functions.Messages.success('Facility User Successfully Identified')
                this.$router.push({ name: 'studio_user_list', params: { studioID: this.$route.params.studioID} })
              }else {
                this.$functions.Messages.error('Action failed!')
              }
            })
          // }
          // else { //doğrulanamadı
          //   this.isFinal = true; //save basınca uyarıları tetikleyecek
          // }
      //}
    },
         facilityCreateNext() {

          if (this.$refs.transferWizardIndex.activeTabIndex === 0) {
            if (
                this.$v.personData.email.required && //eğer valid doğrulanırsa
                this.$v.personData.email.email
            ) {
              this.$database.AccountService.get_user_by_mail({
                EMail: this.personData.email,
                CompanyBranchId: this.personData.company_branch_id,
              })
                  .then((res) => {
                    if (res.IsSuccess === true || res.IsSuccess === 1) {
                      this.$router.push({
                        name: 'customerEdit',
                        params: { personID: res.Result.PersonId },
                      })
                    } else {
                      this.$refs.transferWizardIndex.nextTab()
                    }
                  })
            } else {
              //doğrulanamadıysa
              this.isNext = true //uyarı mesajlarını tetikle
            }
          }
        },


    getGender(val) {
      // this.gender = val;
      if (val === "female") {
        this.femaleColor = "primary";
        this.maleColor = "outline-secondary";
        this.personData.gender = 0;
      } else if (val === "male") {
        this.personData.gender = 1;
        this.maleColor = "primary";
        this.femaleColor = "outline-secondary";
      } else {
        this.gender = 3;
      }
    },
    removePhoto() {
      this.userImg = 'https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png'
    },
     toggleShow() {
				this.show = !this.show;
			},
            /**s
			 * crop success
			 *
			 * [param] userImg
			 * [param] field
			 */
			cropSuccess(userImg, field){
				this.userImg = userImg;
        // console.log(this.userImg);
      },
			/**
			 * upload success
			 *
			 * [param] jsonData  server api return data, already json encode
			 * [param] field
			 */
			cropUploadSuccess(jsonData, field){
				// console.log('-------- upload success --------');
				// console.log(jsonData);
				// console.log('field: ' + field);
			},
			/**
			 * upload fail
			 *
			 * [param] status    server api return error status, like 500
			 * [param] field
			 */
			cropUploadFail(status, field){
				// console.log('-------- upload fail --------');
				// console.log(status);
				// console.log('field: ' + field);
			},
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
  },
  computed:{


    nextStep:function(){
  alert("sdsad");

      return this.$v.personData.email.required && this.$v.personData.email.email
    }
  },
  validations: {
     personData: {
          email: {
            email,
            required
          },
          name: {
            required,
          },
          last_name: {
            required
          },
          phone_number: {
            required: required,
            numeric: numeric,
            maxLength: maxLength(20)
          },

          birthday: {
            required: required
          },
          gender: {
            numeric: numeric
          }

        }
  },
  created() {
    this.personData.company_branch_id = this.$route.params.studioID
    
    this.userImg = 'https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png'
  },
};
</script>

<!-- stillendirme -->
<style lang="css" scoped src="@/styles/css/personCreate.css"></style>
<style lang="scss" src="@/styles/scss/personCreate.scss"></style>
