import { render, staticRenderFns } from "./facilityUserCreate.vue?vue&type=template&id=4cb3dc0e&scoped=true&"
import script from "./facilityUserCreate.vue?vue&type=script&lang=js&"
export * from "./facilityUserCreate.vue?vue&type=script&lang=js&"
import style0 from "@/styles/css/personCreate.css?vue&type=style&index=0&id=4cb3dc0e&lang=css&scoped=true&"
import style1 from "@/styles/scss/personCreate.scss?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cb3dc0e",
  null
  
)

export default component.exports